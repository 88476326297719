

a {
    text-decoration: none;
    color: #e5edf1;
}

a:hover {
    color: #446da3;
}


.comment-icon {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  
.ctitle {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Comments container */
.comments {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #ffffff;
    padding: 20px;
    margin: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
    color: #0c6fad;
    font-size: 24px;
}

.profilelink {
    font-weight: bold;
    color: #0c6fad;
}

.cname,
.cticker,
.lors,
.aclass,
.thees,
.cfail,
.mcaptofp,
.targetmcapc,
.timelinec,
.riskc,
.tprc,
.arc,
.cc,
.ac {
    font-size: 16px;
    margin-bottom: 10px;
}

.tusc {
    font-size: 16px;
    margin-bottom: 10px;
    color: #f79e42;
    font-weight: bold;
}

hr {
    border: 0;
    border-top: 1px solid #e7e7e7;
    margin: 20px 0;
}

/* Comment section */
.thecommentc,
.theauthorc,
.datec {
    font-size: 14px;
    margin-bottom: 5px;
}

.nocommentsc {
    font-size: 14px;
    color: #999;
}

/* Form */
form {
    margin-top: 20px;
}

.labelcc {
    display: block;
    margin-bottom: 10px;
}

.textofcommentarea {
    display: block;
    width: 100%;
    height: 100px;
    padding: 5px;
    font-size: 14px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
}

.subbuttonhere {
    display: inline-block;
    background-color: #0c6fad;
    color: #fff;
    padding: 10px 20px;
    font-size: 14px;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}

.subbuttonhere:hover {
    background-color: #f79e42;
}
