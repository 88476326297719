.signuptitle {
  position: relative;
  top: 50px;
  color: #ffffff;
}

.signup-form {
  position: relative;
  top: 50px
}

.signup-email input,
.signup-username input,
.signup-password input,
.signup-passwordconfirm input {
  color: #ffffff;
}

.signup-email label,
.signup-username label,
.signup-password label,
.signup-passwordconfirm label {
  color: #4b839c;
}



