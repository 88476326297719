input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  margin: 20px 0;
  border-radius: 4px;
  background: #d3d3d3;
  outline: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px; /* Smaller size */
  height: 12px; /* Smaller size */
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25); /* Subtle lift effect */
  margin-top: -4px; /* Alignment adjustment */
}

input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #45a049;
}

input[type="range"]::-moz-range-thumb {
  width: 12px; /* Smaller size */
  height: 12px; /* Smaller size */
  background-color: #4caf50;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25); /* Subtle lift effect */
}

input[type="range"]::-moz-range-thumb:hover {
  background-color: #45a049;
}

/* ... continue with the rest of your CSS ... */


input[type="range"]::-webkit-slider-runnable-track {
  height: 4px;
  background: #d3d3d3;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-moz-range-track {
  height: 4px;
  background: #d3d3d3;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-ms-track {
  height: 4px;
  background: #d3d3d3;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-ms-fill-lower {
  background-color: #cc2a2a;
}

input[type="range"]::-webkit-slider-thumb:active {
  transform: scale(1.1);
  background-color: #45a049;
}

input[type="range"]::-moz-range-thumb:active {
  transform: scale(1.1);
  background-color: #45a049;
}

input[type="range"]::-ms-thumb:active {
  transform: scale(1.1);
  background-color: #45a049;
}

/* Red section */
input[type="range"][value="-10"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 100%, #d3d3d3 0%);
}

input[type="range"][value="-9"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 90%, #d3d3d3 10%);
}

input[type="range"][value="-8"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 80%, #d3d3d3 20%);
}

input[type="range"][value="-7"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 70%, #d3d3d3 30%);
}

input[type="range"][value="-6"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 60%, #d3d3d3 40%);
}

input[type="range"][value="-5"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 50%, #d3d3d3 50%);
}

input[type="range"][value="-4"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 40%, #d3d3d3 60%);
}

input[type="range"][value="-3"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 30%, #d3d3d3 70%);
}

input[type="range"][value="-2"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 20%, #d3d3d3 80%);
}


input[type="range"][value="-1"]::-webkit-slider-runnable-track {
  background: linear-gradient(to right, #cc2a2a 10%, #d3d3d3 90%);
}

input[type="range"][value="0"]::-webkit-slider-runnable-track {
  background: #d3d3d3;
}

/* Green section */
input[type="range"][value="1"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 10%, #d3d3d3 90%);
}

input[type="range"][value="2"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 20%, #d3d3d3 80%);
}

input[type="range"][value="3"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 30%, #d3d3d3 70%);
}

input[type="range"][value="4"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 40%, #d3d3d3 60%);
}

input[type="range"][value="5"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 50%, #d3d3d3 50%);
}

input[type="range"][value="6"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 60%, #d3d3d3 40%);
}

input[type="range"][value="7"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 70%, #d3d3d3 30%);
}

input[type="range"][value="8"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 80%, #d3d3d3 20%);
}

input[type="range"][value="9"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 90%, #d3d3d3 10%);
}

input[type="range"][value="10"]::-webkit-slider-runnable-track {
  background: linear-gradient(to left, #3cdf93 100%, #d3d3d3 0%);
}

/* ... continue in the same way for each step from 4 to 10 ... */