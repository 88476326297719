
  .login-email input,
  .login-username input,
  .login-password input,
  .login-passwordconfirm input {
    color: #ffffff;
  }
  
  .login-email label,
  .login-username label,
  .login-password label,
  .login-passwordconfirm label {
    color: #4b839c;
  }
  
  
  
  