

.Createpost {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    box-sizing: border-box;
    color: #ffffff;
}

.Createpost h1 {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 1.5rem;
}

.Createpost label {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

.thetextboxfforthesis {
    height: 300px;
}

.fail {
    height: 150px;
}

.Createpost textarea,
.Createpost input,
.Createpost select {
    width: 100%;
    padding: .5rem;
    font-size: 1rem;
    border: 1px solid #1a3365;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 1rem;
}

/* .Createpost button {
    background-color: #1a3365;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    padding: .5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}
 */

/* .Createpost button:hover {
    background-color: #e0d5c4;
} */

.submitbut {
    width: 100%;
}

.submitbut:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.submitbut:disabled:hover {
    background-color: #1a3365;
}
