.tl {
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    position: relative;
    background-color: rgb(6, 46, 73); /* Dark blue background */
}



  .PGname {
    font-size: 24px;
    font-weight: bold;
    color: #131252;
    margin: 0;
    font-family: 'Roboto', sans-serif;
  }

/* Normal state */
.hoverEffect {
  transition: 0.3s;
}

/* Hover state */
.hoverEffect:hover {
  cursor: pointer;
  filter: brightness(0.8);
}


.bigtitle{
    font-size: 3rem;
    color: #ffffff; /* White text color */
    margin-bottom: 10px;
    text-align: center;
}

.box {
    color: #ffffff;
    border: 3px solid #00385a; /* Lighter blue border */
    border-radius: 3px;
    box-shadow: 0 0 5px #000000; /* Lighter blue box shadow */
    margin: 3px auto;
    padding: 80px;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 400px;
}

.a {
    color: white;
}

.asmallline {
    width: 100%;
    height: 2px; /* Adjust this value to change the thickness of the line */
    margin: 0 auto;
    margin-bottom: 10px;
    background-color: #ffffff; /* White color */
}

.thekey {
    width: 450px;
}

.ratebutton, .commentbutton, .refreshbutton, .signoutbutton, .createpostbutton, .profilebutton, .getavasopinion {
    background-color: #004f7c; /* Lighter blue background color */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    padding: 12px 25px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}
  
.ratebutton:hover, .commentbutton:hover, .refreshbutton:hover, .signoutbutton:hover, .createpostbutton:hover, .profilebutton:hover, .getavasopinion:hover {
    background-color: #72a6d9; /* Slightly lighter blue on hover */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}
  
.ratebutton:active, .commentbutton:active, .refreshbutton:active, .signoutbutton:active, .createpostbutton:active, .profilebutton:active, .getavasopinion:active {
    background-color: #004085;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(2px);
}

/* App.css */
.loader {
    position: relative;
    top: 1px;
    left: 45%;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
