/* Home styling */
.Home {
  background-color: rgb(6, 46, 73); /* Dark blue background */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 100px;
  overflow: auto;
}

.titleOfPG {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-bottom: 8px;
}

.slogan {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 24px;
}

.loginbutton, .signupbutton {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  background-color: #4a8af4;
  color: #ffffff;
  border: 1px solid #4a8af4;
  padding: 8px 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 4px;
  border-radius: 4px;
}

.loginbutton:hover, .signupbutton:hover {
  background-color: #3a6ec4;
  color: #ffffff;
  border: 1px solid #3a6ec4;
}
