.yourposts {
    font-size: 2rem;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }
  
  .aforthis {
    text-decoration: none;
    color: #333;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 15px; /* Adjust the spacing between the elements */
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.1); /* Add a background color */
    border-radius: 5px; /* Add rounded corners */
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .aforthis:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  .user-id,
  .user-rating,
  .user-org-position {
    font-size: 0.9rem; /* Adjust the font size */
    color: #fff;
    text-align: center;
  }
  
  .user-rating {
    font-weight: bold;
  }
  

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: calc(100vw);

  }
  
  .user-list-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: calc(100vw - 5px);
  }
  
  